import React from 'react'
import {Navbar, Nav, Button} from 'react-bootstrap'
import {Link} from 'react-router-dom';
import Logo from '../../logo/Logo1.png';
import './header.scss';
import Image from "react-bootstrap/Image";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

function Header() {

    const {i18n} = useTranslation();
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };
    const translation = useTranslation().t;

    const location = useLocation();
    const splitLocation = location.pathname.split("/");

    return (
        <>
            <Navbar collapseOnSelect className="color-nav" expand="lg">
                <Navbar.Brand>
                    <Image height="100" src={Logo} alt="ITnent" className="logo-image"/>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="m-auto">
                        <Nav.Link eventKey={1} className={`m-2 ${splitLocation[1] === "" ? "active" : ""}`} as={Link}
                                  to="/">{translation("header.about-us")}</Nav.Link>
                        <Nav.Link eventKey={3}
                                  className={`m-2 ${splitLocation[1] === "companyStandards" ? "active" : ""}`} as={Link}
                                  to="/companyStandards">{translation("header.company-standards")}</Nav.Link>
                        <Nav.Link eventKey={4} className={`m-2 ${splitLocation[1] === "career" ? "active" : ""}`}
                                  as={Link} to="/career">{translation("header.career")}</Nav.Link>
                        <Nav.Link eventKey={5} className={`m-2 ${splitLocation[1] === "contacts" ? "active" : ""}`}
                                  as={Link} to="/contacts">{translation("header.contacts")}</Nav.Link>
                    </Nav>
                    <div className="m-2">
                        {/*<Button className="m-1" variant="outline-light" size="sm" onClick={() => changeLanguage("ru")}>RU</Button>*/}
                        {/*<Button className="m-1" variant="outline-light" size="sm" onClick={() => changeLanguage("en")}>EN</Button>*/}
                    </div>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}

export default Header
