import React from 'react';
import './footer.scss';

function Footer() {
    return (
        <div className="local-scss d-none d-lg-block">
            <b>Copyright © {(new Date().getFullYear())} evercodo</b>
        </div>
    );
}

export default Footer;
