import React from 'react';
import './contacts.scss';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {useTranslation} from "react-i18next";
import Pavel from '../../../image/Pavel.png';
import Dmitry from '../../../image/Dmitry.png'

function Contacts() {

    const translation = useTranslation().t;

    return (
        <Container>
            <div className="background-img-contacts text-color-contacts custom-size">
                <Row className="mt-lg-5 mb-lg-5 mt-sm-5 mb-sm-5">
                    <div><h1><b className="size-title-contacts custom-word-break">{translation("title.contact-us")}</b>
                    </h1></div>
                </Row>

                <Row className="mt-lg-5 mb-lg-5 mt-sm-5 mb-sm-5">

                    <Col xs={12} sm={12} md={12} lg={2} className="mt-sm-2">
                        {/*<div>*/}
                            <div className="mt-sm-2">
                                <div><h3>CEO:</h3></div>
                                <div>{translation("description.ceo")}</div>
                            </div>

                            <div className="mt-sm-2">
                                <div ><h3>{translation("description.phone.title")}:</h3></div>
                                <div>+972 534 258 278</div>
                            </div>

                            <div className="mt-sm-2">
                                <div ><h4>E-mail:</h4></div>
                                <div><a href='mailto:ceo@evercodo.com'>ceo@evercodo.com</a></div>
                        </div>

                    </Col>
                    <Col xs={12} sm={12} md={12} lg={3} className="mb-sm-2">
                        <div className="mt-sm-2">
                            <div><img className="mt-sm-5 custom-img-contacts" height="210" src={Pavel} alt={"Pavel"}/>
                            </div>
                        </div>
                    </Col>


                    <Col xs={12} sm={12} md={12} lg={7} className="mt-sm-2 border-left-line">
                        <div className="mt-sm-2 border-top-line">
                            <Row>
                                <h3>
                                    <div className="custom-contacts">{translation("description.address.title")}</div>
                                </h3>
                            </Row>
                            <Row>
                                <div>{translation("description.address.city")}</div>
                            </Row>
                            <Row className="mt-lg-5 mt-sm-5 custom-height-row-contacts">
                                <div className="h_iframe">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d838.4180010661445!2d34.991265488126295!3d32.8009551260803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5e44dd843befb419!2zMzLCsDQ4JzAzLjQiTiAzNMKwNTknMzAuNSJF!5e0!3m2!1sen!2sru!4v1653481523790!5m2!1sen!2sru"
                                    />
                                </div>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <div className="custom-push"/>
            </div>
        </Container>
    )
        ;
}



export default Contacts;
