import React from "react";
import {useTranslation} from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./about.scss";

function About() {

    const translation = useTranslation().t;

    return (
        <Container>
            <div className="background-about-img-about text-color">
                <Row className="mt-lg-5 mb-lg-5 mt-sm-5 mb-sm-4">
                    <div><h1><b className="custom-word-break size-title-about">{translation("title.about")}</b></h1></div>
                </Row>

                <Row>
                    <Col xs={12} sm={12} md={12} lg={4}>
                        <div className="mb-lg-5 mb-sm-2 custom-word-break size-words-about size-words-mobile-about">
                            {translation("about.left-text")}
                        </div>
                        {/*<div className="border-top-line border-left-line">*/}
                        {/*    <div className="custom-size-about ms-lg-2 mt-sm-5">{translation("about.counters-block")}</div>*/}
                        {/*    <div className="year-about ms-lg-2 mt-sm-2"><b>{translation("about.year")}</b></div>*/}
                        {/*    <div className="custom-size ms-lg-2 mt-sm-2">{translation("about.year1")}</div>*/}
                        {/*</div>*/}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={8} className="mb-sm-2 border-left-line">
                        <div className="border-top-line">
                            <div className="custom-size-about mt-sm-2" >
                                {translation("about.company")}
                            </div>

                            <div className="custom-size mt-sm-2">
                                {translation("about.developers")}
                            </div>

                            <div className="custom-size mt-sm-2">
                                {translation("about.projects")}
                            </div>

                            <div className="custom-size mt-sm-2">
                                {translation("about.complex-event")}
                            </div>

                            {/*<div className="custom-size mt-sm-2">*/}
                            {/*    {translation("about.patent1")} <a*/}
                            {/*    href='https://encrypted.google.com/patents/WO2015094004A1?hl=ru&cl=en'>WO 2015094004*/}
                            {/*    A1</a>{translation("about.patent2")}*/}
                            {/*</div>*/}

                            {/*<div className="custom-size mt-sm-2">*/}
                            {/*    {translation("about.experience1")}<b>{translation("about.siemens")}</b>{translation("about.experience2")}*/}
                            {/*</div>*/}
                        </div>
                    </Col>
                </Row>
                <div className="custom-push"/>
            </div>
        </Container>
    );
}

export default About;
